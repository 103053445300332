<template>
  <div class="page bg-white" id="reorganize">
    <div class="pageHeight">
      <el-steps v-if="butType == 0" :active="step" align-center style="margin-bottom: 20px">
        <el-step title="基本信息"></el-step>
        <el-step title="修复藏品"></el-step>
        <el-step title="预览"></el-step>
      </el-steps>

      <div v-show="step == 0 || step == 2 || step == 99">
        <el-row class="addRepairs">
          <el-form :model="inputForm" ref="inputForm" :rules="rules" label-width="120px"
                   size="small">
            <el-col :span="11">
              <el-form-item label="修复事项" prop="repairMatter">
                <el-input
                    v-model.trim="inputForm.repairMatter"
                    placeholder="请输入修复事项(限100字)"
                    maxlength="100"
                    :disabled="inputType"
                    clearable
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item label="修复时间" prop="mttr">
                <el-date-picker
                    v-model="inputForm.mttr"
                    type="daterange"
                    style="width: 100%;"
                    :disabled="inputType"
                    value-format="yyyy-MM-dd"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期">
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item label="修复机构" prop="repairInstitution">
                <el-input
                    v-model.trim="inputForm.repairInstitution"
                    placeholder="请输入修复机构(限50字)"
                    maxlength="50"
                    :disabled="inputType"
                    clearable
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item label="经手人" prop="repairHandled">
                <el-input
                    v-model.trim="inputForm.repairHandled"
                    placeholder="请输入经手人(限50字)"
                    maxlength="50"
                    :disabled="inputType"
                    clearable
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item label="专家姓名" prop="repairExpert">
                <el-input
                    v-model.trim="inputForm.repairExpert"
                    placeholder="请输入专家姓名(限50字)"
                    maxlength="50"
                    :disabled="inputType"
                    clearable
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item label="修复类型" prop="repairTypes">
                <el-select
                    v-model="inputForm.repairTypes"
                    clearable
                    placeholder="请选择修复类型"
                    :disabled="inputType"
                    style="width: 100%"
                >
                  <el-option
                      v-for="item in this.$dictUtils.getDictList('repair_types')"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item label="预计修复费用" prop="repairCost">
                <el-input
                    v-model.trim.number="inputForm.repairCost"
                    placeholder="请输入预计修复费用"
                    @input="jiage"
                    @blur="save2Data"
                    maxlength="10"
                    :disabled="inputType"
                    clearable
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="22">
              <el-form-item label="修复前状况" prop="repairCondition">
                <el-input type="textarea" rows="5" resize="none" :disabled="inputType"
                          v-model.trim="inputForm.repairCondition" maxlength="200"
                          placeholder="请输入修复前状况(限200字)"
                          clearable></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="22">
              <el-form-item label="修复原因" prop="repairReason">
                <el-input type="textarea" rows="5" resize="none" :disabled="inputType"
                          v-model.trim="inputForm.repairReason" maxlength="500"
                          placeholder="请输入修复原因(限500字)"
                          clearable></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="22">
              <el-form-item label="修复方式" prop="repairProgramme">
                <el-input type="textarea" rows="5" resize="none" :disabled="inputType"
                          v-model.trim="inputForm.repairProgramme" maxlength="500"
                          placeholder="请输入修复方式(限500字)"
                          clearable></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="22">
              <el-form-item label="修复位置" prop="repairPosition">
                <el-input type="textarea" rows="5" resize="none" :disabled="inputType"
                          v-model.trim="inputForm.repairPosition" maxlength="200"
                          placeholder="请输入修复位置(限200字)"
                          clearable></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="22">
              <el-form-item label="备注" prop="repairRemark">
                <el-input type="textarea" rows="5" resize="none" :disabled="inputType"
                          v-model.trim="inputForm.repairRemark" maxlength="100"
                          placeholder="其它需要特别说明的事项或信息(限100字)"
                          clearable></el-input>
              </el-form-item>
            </el-col>
            <div v-if="step == 99">
              <el-col :span="24">
                <div class="minTitle">修复记录</div>
              </el-col>
              <el-col :span="22">
                <el-form-item label="附件" prop="repairResultFileList">
                  <div v-for="item in inputForm.repairResultFileList">
                    <div class="flex_l_e">
                      <img :src="require('@/assets/img/filePDF.png')" width="30px">
                      <div style="margin-left: 10px" class="omit">{{ item.fileName }}</div>
                    </div>
                    <div>
                      <el-button size="mini" type="text"
                                 @click="previewPdf(item.netUrl)">
                        预览
                      </el-button>
                      <el-button size="mini" type="text"
                                 @click="downFiile(item.netUrl,item.fileName)">
                        下载
                      </el-button>
                    </div>
                  </div>
                </el-form-item>
              </el-col>
              <el-col :span="22">
                <el-form-item label="病害情况" prop="resultDisease">
                  <el-input type="textarea" rows="3" resize="none" :disabled="inputType"
                            v-model.trim="inputForm.resultDisease" maxlength="200"
                            placeholder="请输入目前病害情况(限200字)"
                            clearable></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="22">
                <el-form-item label="修复前说明" prop="resultBeforeIllustrate">
                  <el-input type="textarea" rows="3" resize="none" :disabled="inputType"
                            v-model.trim="inputForm.resultBeforeIllustrate" maxlength="200"
                            placeholder="请输入修复前说明(限200字)"
                            clearable></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="22">
                <el-form-item label="修复中说明" prop="resultDuringIllustrate">
                  <el-input type="textarea" rows="3" resize="none" :disabled="inputType"
                            v-model.trim="inputForm.resultDuringIllustrate" maxlength="200"
                            placeholder="请输入修复中说明(限200字)"
                            clearable></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="22">
                <el-form-item label="修复后说明" prop="resultAfterIllustrate">
                  <el-input type="textarea" rows="3" resize="none" :disabled="inputType"
                            v-model.trim="inputForm.resultAfterIllustrate" maxlength="200"
                            placeholder="请输入修复后说明(限200字)"
                            clearable></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="22">
                <el-form-item label="修复效果说明" prop="resultEffectIllustrate">
                  <el-input type="textarea" rows="3" resize="none" :disabled="inputType"
                            v-model.trim="inputForm.resultEffectIllustrate" maxlength="200"
                            placeholder="请输入修复效果说明(限200字)"
                            clearable></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="22">
                <el-form-item label="备注" prop="resultRemark">
                  <el-input type="textarea" rows="3" resize="none" :disabled="inputType"
                            v-model.trim="inputForm.resultRemark" maxlength="200"
                            placeholder="请输入备注(限200字)"
                            clearable></el-input>
                </el-form-item>
              </el-col>
            </div>
          </el-form>
        </el-row>
      </div>

      <div v-if="step == 1 || step == 2">
        <div v-if="step != 2">
          <el-button size="small" type="primary" @click="addDataList(1,generalList)" icon="el-icon-plus">
            总账藏品
          </el-button>
          <el-button size="small" type="primary" @click="addDataList(2,auxiliaryList)" icon="el-icon-plus">
            辅助帐藏品
          </el-button>
          <el-button size="small" type="primary" @click="addDataList(3,immovableList)" icon="el-icon-plus">
            不可移动文物
          </el-button>
        </div>
        <div class="flex_b_c">
          <div>
            <div class="minTitle">总账藏品清单</div>
            <div style="margin-left: 25px">已选{{ generalList.length }}件藏品</div>
          </div>
          <div class="text_right" v-if="step != 2">
            <el-button icon="el-icon-delete" size="small" type="danger"
                       @click="deleteListData(generalList,selectGeneralList,'generalList')">批量删除
            </el-button>
          </div>
        </div>
        <el-table
            :data="generalList"
            size="small"
            style="padding-left: 20px"
            height="300px"
            class="table"
            ref="generalList"
            :row-key="'id'"
            :header-cell-style="{background:'#F9DFDF'}"
            @selection-change="selectionChangeGenera"
        >
          <el-table-column :reserve-selection="true" type="selection" width="50"/>
          <el-table-column label="序号" type="index" width="50"></el-table-column>
          <el-table-column prop="picMasterViewUrl" label="图标" width="120px">
            <template slot-scope="scope">
              <div class="flex_a_c">
                <div v-if="scope.row.carded == 0" class="icon-wenwukapian iconfont listIcon"></div>
                <el-image
                    style="width: 36px; height: 36px"
                    :src="scope.row.picMasterViewUrl"
                    :fit="'cover'"
                    :preview-src-list="[scope.row.picMasterViewUrl]"
                >
                  <div slot="error" class="image-slot">
                    <el-image
                        style="width: 36px; height: 36px"
                        :src="require('@/assets/img/default.png')"
                        :fit="'cover'">
                    </el-image>
                  </div>
                </el-image>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="collectionName" label="藏品名称"
                           show-overflow-tooltip></el-table-column>
          <el-table-column prop="helpNum" label="辅助账编号" show-overflow-tooltip></el-table-column>
          <el-table-column prop="generalNum" label="总账编号" show-overflow-tooltip></el-table-column>
          <el-table-column prop="oneTypeName" label="藏品分类"
                           show-overflow-tooltip></el-table-column>
          <el-table-column prop="identified" label="是否鉴定" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ scope.row.identified == 0 ? '是' : '否' }}
            </template>
          </el-table-column>
          <el-table-column prop="leaveList" label="级别" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ getLeaveList(scope.row.collectionLevel) }}
            </template>
          </el-table-column>
          <el-table-column prop="integrityInfo" label="完残情况" show-overflow-tooltip></el-table-column>
          <el-table-column prop="integrity" label="完残程度" show-overflow-tooltip></el-table-column>
          <el-table-column prop="cStoreState" label="库存状态" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ $dictUtils.getDictLabel("cStore_state", scope.row.cStoreState, '-') }}
            </template>
          </el-table-column>
        </el-table>

        <div class="flex_b_c">
          <div>
            <div class="minTitle">辅助账藏品清单</div>
            <div style="margin-left: 25px">已选{{ auxiliaryList.length }}件藏品</div>
          </div>
          <div class="text_right" v-if="step != 2">
            <el-button icon="el-icon-delete" size="small" type="danger"
                       @click="deleteListData(auxiliaryList,selectAuxiliaryList,'auxiliaryList')">批量删除
            </el-button>
          </div>
        </div>
        <el-table
            :data="auxiliaryList"
            size="small"
            style="padding-left: 20px"
            height="300px"
            class="table"
            ref="auxiliaryList"
            :row-key="'id'"
            :header-cell-style="{background:'#F9DFDF'}"
            @selection-change="selectionChangeAuxiliary"
        >
          <el-table-column :reserve-selection="true" type="selection" width="50"/>
          <el-table-column label="序号" type="index" width="50"></el-table-column>
          <el-table-column prop="picMasterViewUrl" label="图标" width="120px">
            <template slot-scope="scope">
              <div class="flex_a_c">
                <div v-if="scope.row.carded == 0" class="icon-wenwukapian iconfont listIcon"></div>
                <el-image
                    style="width: 36px; height: 36px"
                    :src="scope.row.picMasterViewUrl"
                    :fit="'cover'"
                    :preview-src-list="[scope.row.picMasterViewUrl]"
                >
                  <div slot="error" class="image-slot">
                    <el-image
                        style="width: 36px; height: 36px"
                        :src="require('@/assets/img/default.png')"
                        :fit="'cover'">
                    </el-image>
                  </div>
                </el-image>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="collectionName" label="藏品名称"
                           show-overflow-tooltip></el-table-column>
          <el-table-column prop="helpNum" label="辅助账编号" show-overflow-tooltip></el-table-column>
          <el-table-column prop="generalNum" label="总账编号" show-overflow-tooltip></el-table-column>
          <el-table-column prop="oneTypeName" label="藏品分类"
                           show-overflow-tooltip></el-table-column>
          <el-table-column prop="identified" label="是否鉴定" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ scope.row.identified == 0 ? '是' : '否' }}
            </template>
          </el-table-column>
          <el-table-column prop="leaveList" label="级别" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ getLeaveList(scope.row.collectionLevel) }}
            </template>
          </el-table-column>
          <el-table-column prop="integrityInfo" label="完残情况" show-overflow-tooltip></el-table-column>
          <el-table-column prop="integrity" label="完残程度" show-overflow-tooltip></el-table-column>
          <el-table-column prop="cStoreState" label="库存状态" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ $dictUtils.getDictLabel("cStore_state", scope.row.cStoreState, '-') }}
            </template>
          </el-table-column>
        </el-table>

        <div class="flex_b_c">
          <div>
            <div class="minTitle">不可移动文物清单</div>
            <div style="margin-left: 25px">已选{{ immovableList.length }}件藏品</div>
          </div>
          <div class="text_right" v-if="step != 2">
            <el-button icon="el-icon-delete" size="small" type="danger"
                       @click="deleteListData(immovableList,selectImmovableList,'immovableList')">批量删除
            </el-button>
          </div>
        </div>
        <el-table
            :data="immovableList"
            size="small"
            style="padding-left: 20px"
            height="300px"
            ref="immovableList"
            class="table"
            :row-key="'id'"
            :header-cell-style="{background:'#F9DFDF'}"
            @selection-change="selectionChangeImmovable"
        >
          <el-table-column :reserve-selection="true" type="selection" width="50"/>
          <el-table-column label="序号" type="index" width="50"></el-table-column>
          <el-table-column prop="picMasterViewUrl" label="图标" width="120px">
            <template slot-scope="scope">
              <div class="flex_a_c">
                <div v-if="scope.row.carded == 0"
                     class="icon-wenwukapian iconfont listIcon"></div>
                <el-image
                    style="width: 36px; height: 36px"
                    :src="scope.row.picMasterViewUrl"
                    :fit="'cover'"
                    :preview-src-list="[scope.row.picMasterViewUrl]"
                >
                  <div slot="error" class="image-slot">
                    <el-image
                        style="width: 36px; height: 36px"
                        :src="require('@/assets/img/default.png')"
                        :fit="'cover'">
                    </el-image>
                  </div>
                </el-image>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="name" label="文物名称"
                           show-overflow-tooltip></el-table-column>
          <el-table-column prop="culturalRelicsCategory" label="文物类别"
                           show-overflow-tooltip></el-table-column>
          <el-table-column prop="culturalRelicsType" label="文物分类" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ scope.row.culturalRelicsType ? scope.row.culturalRelicsType : scope.row.other }}
            </template>
          </el-table-column>
          <el-table-column prop="quantity" label="数量" show-overflow-tooltip></el-table-column>
          <el-table-column prop="year" label="年份" show-overflow-tooltip></el-table-column>
          <el-table-column prop="heritageAppraisal" label="现状评估" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ $dictUtils.getDictLabel("assessment_status", scope.row.heritageAppraisal, '-') }}
            </template>
          </el-table-column>
          <el-table-column prop="integrityInfo" label="完残情况" show-overflow-tooltip></el-table-column>
          <el-table-column prop="integrity" label="完残程度" show-overflow-tooltip></el-table-column>
        </el-table>
      </div>
      <add-inventory ref="addInventory"></add-inventory>
      <reviewSuggestions ref="suggestions" @updataStatus="returnPage()"></reviewSuggestions>
    </div>

    <div v-show="step == 0 || step == 2" style="margin-top: 10px">
      <div class="text_center" v-if="step != 2">
        <el-button size="small" @click="returnPage(1)">取消</el-button>
        <el-button size="small" type="primary" @click="nextStep(0)">下一步</el-button>
      </div>
    </div>
    <div v-if="step == 1 || step == 2" style="margin-top: 10px">
      <div class="text_center" v-if="step == 1">
        <el-button size="small" @click="returnPage(1)">取消</el-button>
        <el-button size="small" type="primary" @click="nextStep(1)">上一步</el-button>
        <el-button size="small" type="primary" @click="nextStep(0)">预览</el-button>
      </div>

      <div class="text_center" v-else-if="step == 2 && butType != 1 && butType != 4">
        <el-button size="small" @click="returnPage(1)">取消</el-button>
        <el-button size="small" type="primary" @click="nextStep(1)">上一步</el-button>
        <el-button size="small" type="primary" @click="saveAsDraft(0)" v-no-more-click>保存为草稿</el-button>
        <el-button size="small" type="primary" @click="saveAsDraft(1)" v-no-more-click>提交</el-button>
      </div>
      <div class="text_center" v-else>
        <el-button v-if="butType == 4 && !see" size="small" type="primary" @click="switchData(1)">上一条
        </el-button>
        <el-button v-if="butType == 4 && !see" size="small" type="primary" @click="switchData(0)">下一条
        </el-button>
        <el-button size="small" v-if="butType == 4 && see != 1 && this.inputForm.repairState == 1"
                   type="primary"
                   @click="through(3)">通过
        </el-button>
        <el-button size="small" v-if="butType == 4 && see != 1 && this.inputForm.repairState == 1"
                   type="danger"
                   @click="through(2)">驳回
        </el-button>
        <el-button size="small" @click="returnPage(1)">关闭</el-button>
      </div>
    </div>

    <div v-show="step == 99" style="margin-top: 10px" class="text_center">
      <el-button size="small" @click="returnPage(1)">关闭</el-button>
    </div>
  </div>

</template>

<script>
import AddInventory from "@/views/modules/collection/business/collIdentification/addInventory.vue";
import reviewSuggestions from "@/views/modules/collection/accounts/module/reviewSuggestions.vue";
import {Base64} from 'js-base64'

export default {
  name: "addRepair",
  components: {reviewSuggestions, AddInventory},
  data() {
    return {
      id: '',
      step: 0,
      inputForm: {
        repairMatter: '',
        mttr: [],
        repairInstitution: '',
        repairHandled: '',
        repairExpert: '',
        repairCost: '',
        repairCondition: '',
        repairReason: '',
        repairProgramme: '',
        repairPosition: '',
        repairRemark: '',
      },
      rules: {
        mttr: [
          {type: 'array', required: true, message: '请选择修复时间', trigger: 'blur'}
        ],
        repairTypes: [
          {required: true, message: "请选择修复类型", trigger: "change"},
        ],
      },

      generalList: [],
      selectGeneralList: [],

      auxiliaryList: [],
      selectAuxiliaryList: [],

      immovableList: [],
      selectImmovableList: [],

      registrationSearch: {},

      inputVisible: false,
      inputValue: '',

      inputType: false,
      returnPageStatus: '',
      butType: "", //0新增 1详情 2修改 4审核
      leaveList: [],

      dataId:'', //藏品id
    }
  },

  mounted() {
    this.id = this.$route.query.id
    this.butType = this.$route.query.butType
    this.see = this.$route.query.see
    this.dataId = this.$route.query.dataId
    if (this.butType == 1 || this.butType == 4) {
      this.inputType = true
      this.step = 2
    }
    if (this.id) {
      this.getRowDetail()
    }
    this.setPageTitle()

    this.$axios(this.api.collection.listLevelSelect).then(data => {
      if (data.status) {
        this.leaveList = data.data
      }
    })
  },

  methods: {
    //0征集藏品 1总账藏品 2辅助帐藏品 3不可移动文物
    addDataList(num, data) {
      this.$refs.addInventory.init(num, data)
    },

    selectionChangeGenera(val) {
      this.selectGeneralList = val
    },

    selectionChangeAuxiliary(val) {
      this.selectAuxiliaryList = val
    },

    selectionChangeImmovable(val) {
      this.selectImmovableList = val
    },

    //删除
    deleteListData(data, selectData,refName) {
      if (selectData.length == 0) {
        this.$message.warning('请至少选择一条数据')
        return
      }
      this.$confirm(`您是否确认删除数据?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        selectData.forEach(item2 => {
          data.forEach((item1, index) => {
            if (item1.id == item2.id) {
              data.splice(index, 1)
            }
          })
        })
        this.$refs[refName].clearSelection()
        selectData = []
      })
    },

    getRowDetail() {
      this.$axios(this.api.collection.collectionrepairById, {id: this.id}, 'get').then(data => {
        if (data.status) {
          this.inputForm = data.data
          this.generalList = data.data.ledgerList
          this.auxiliaryList = data.data.auxiliaryList
          this.immovableList = data.data.immovableList
          if (this.inputForm.repairStartTime) {
            this.$set(this.inputForm, 'mttr', [this.inputForm.repairStartTime, this.inputForm.repairEndTime])
          }

          if (this.butType == 5) {
            this.inputType = true
            this.step = 99
            this.getEnclosure()
          }
        }
      })
    },

    //查询修复文件信息
    getEnclosure() {
      this.$axios(this.api.collection.individualCollectionsById, {
        dataId:this.dataId,
        repairId:this.id,
      }, 'get').then(data => {
        if (data.status) {
          console.log(data)
          this.$set(this.inputForm, 'repairResultFileList', data.data.collectionRepairResult.repairResultFileList)
          this.$set(this.inputForm, 'resultDisease', data.data.collectionRepairResult.resultDisease)
          this.$set(this.inputForm, 'resultBeforeIllustrate', data.data.collectionRepairResult.resultBeforeIllustrate)
          this.$set(this.inputForm, 'resultDuringIllustrate', data.data.collectionRepairResult.resultDuringIllustrate)
          this.$set(this.inputForm, 'resultAfterIllustrate', data.data.collectionRepairResult.resultAfterIllustrate)
          this.$set(this.inputForm, 'resultEffectIllustrate', data.data.collectionRepairResult.resultEffectIllustrate)
          this.$set(this.inputForm, 'resultRemark', data.data.collectionRepairResult.resultRemark)
        }
      })
    },

    //预览
    previewPdf(url) {
      window.open(this.$pdfPreviewUrl() + 'onlinePreview?url=' + encodeURIComponent(Base64.encode(url)));
    },

    downFiile(url, name) {
      const xhr = new XMLHttpRequest();
      // 使用open()方法初始化一个请求，第一个参数为请求的类型，第二个参数为请求的地址，第三个参数为是否异步
      xhr.open('GET', url, true);
      // 设置响应的数据类型
      xhr.responseType = 'blob';
      // 当请求加载完成时，触发onload事件
      xhr.onload = () => {
        // 如果请求的状态码为200，表示请求成功
        if (xhr.status === 200) {
          // 创建一个blob对象，第一个参数为响应的数据，第二个参数为blob对象的类型
          const blob = new Blob([xhr.response], {type: xhr.getResponseHeader('content-type')});
          // 创建一个a标签
          const link = document.createElement('a');
          // 为a标签设置href属性，值为blob对象的URL
          link.href = URL.createObjectURL(blob);
          // 为a标签设置下载文件名
          link.download = name;
          // 点击a标签，开始下载文件
          link.click();
        }
      };
      // 发送请求
      xhr.send();
    },

    getLeaveList(leave) {
      let data = this.leaveList.filter(item => {
        return item.level == leave
      })
      if (data.length) {
        return data[0].levelName
      }
    },

    //0下一步 1上一步
    nextStep(num) {
      if (num == 0) {
        if (this.step == 0) {
          this.$refs.inputForm.validate((valid) => {
            if (valid) {
              this.step++;
            }
          })
        } else {
          if (this.step == 1) {
            if (this.generalList.length + this.auxiliaryList.length + this.immovableList.length == 0) {
              this.$message.error('请至少选择一件藏品')
              return
            }
            this.step++;
            this.inputType = true
          }
        }
      } else {
        if (this.step == 2) {
          this.inputType = false
        }
        this.step--;
      }
    },

    //返回
    returnPage(num) {
      this.returnPageStatus = num
      this.$router.back()
    },

    // type 1提交  0保存为草稿
    saveAsDraft(type) {
      let fechUrl = this.api.collection.collectionrepairSave
      let methout = 'post'
      if (this.id) {
        fechUrl = this.api.collection.collectionrepairUpdateById
        methout = 'put'
      }
      let data = JSON.parse(JSON.stringify(this.inputForm))
      this.$set(data, 'repairStartTime', this.inputForm.mttr[0])
      this.$set(data, 'repairEndTime', this.inputForm.mttr[1])
      this.$delete(data, 'mttr')
      let ledgerIds = this.generalList.map(item => {
        return item.id
      })
      let auxiliaryIds = this.auxiliaryList.map(item => {
        return item.id
      })
      let immovableIds = this.immovableList.map(item => {
        return item.id
      })

      this.$axios(fechUrl, {
        ...data,
        repairState: type,
        ledgerIds: ledgerIds,
        auxiliaryIds: auxiliaryIds,
        immovableIds: immovableIds,
      }, methout).then(data => {
        if (data.status) {
          this.$message.success(`${type == 0 ? '保存' : '提交'}成功！`)
          this.programExit = true
          this.returnPage()
        } else {
          this.$message.error(data.msg)
        }
      })
    },

    //type 2驳回 3通过
    through(type) {
      this.$refs.suggestions.init(2, [this.inputForm], type, '', this.api.collection.collectionrepairExamine)
    },

    //1为上一条 0为下一条
    switchData(num) {
      if (Object.keys(this.registrationSearch).length == 0) {
        this.registrationSearch = JSON.parse(sessionStorage.getItem('registrationSearch'))
      }

      this.$axios(this.api.collection.listCollectionRevdInfoNextOrPreRepair, {
        nextInfo: num,
        ...this.registrationSearch
      }, 'get').then((res) => {
        if (res.status) {
          if (res.data) {
            this.id = res.data.id
            if (num == 0) {
              this.registrationSearch.viewCurrent++
            } else {
              this.registrationSearch.viewCurrent--
            }
            this.getRowDetail()
          } else {
            this.$message.error(`已是${num == 1 ? '第一' : '最后'}一条`);
          }
        } else {
          this.$message.error(res.msg);
        }
      })
    },

    setPageTitle() {
      let name = '修复登记详情'
      if (this.butType == 0) {
        name = '修复登记新增'
      } else if (this.butType == 2) {
        name = '修复登记修改'
      }
      this.$store.state.tagsView.visitedViews.forEach(item => {
        if (item.fullPath === this.$route.fullPath) {
          item.title = name
          return
        }
      })
    },

    jiage(value) {
      // 使用正则表达式来限制输入为正数，并保留两位小数
      value = value.replace(/[^\d.]/g, ""); //清除"数字"和"."以外的字符
      value = value.replace(/^\./g, ""); //验证第一个字符是数字
      value = value.replace(/\.{2,}/g, "."); //只保留第一个, 清除多余的
      value = value.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
      value = value.replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3'); //只能输入两位个小数
      this.inputForm.repairCost = value
    },

    save2Data() {
      if (!this.inputForm.repairCost) {
        return
      }
      let menoy = this.inputForm.repairCost.split('.')
      if (menoy.length != 2) {
        this.inputForm.repairCost = this.inputForm.repairCost + '.00'
      } else {
        if (menoy[1].length == 0) {
          this.inputForm.repairCost = this.inputForm.repairCost + '00'
        }
        if (menoy[1].length == 1) {
          this.inputForm.repairCost = this.inputForm.repairCost + '0'
        }
      }
    },
  },

  //离开此页面
  beforeRouteLeave(to, from, next) {
    // 在这里编写逻辑来决定是否允许离开
    // 如果允许离开，调用 next() 方法
    // 如果不允许离开，调用 next(false) 方法
    if (!this.returnPageStatus) {
      sessionStorage.removeItem('listSearch')
      sessionStorage.removeItem('listSearchCollDetail')
    }
    if (this.programExit) {
      this.$store.dispatch('tagsView/delView', this.$route).then(({visitedViews}) => {})
      next();
    } else if (this.butType == 0 || this.butType == 2) {
      this.$confirm("当前内容没有保存，您确定离开吗？", "提示", {
        confirmButtonText: "退出",
        cancelButtonText: "取消",
        type: "warning",
      })
          .then(() => {
            //进去别的页面
            if (this.returnPageStatus){
              this.$store.dispatch('tagsView/delView', this.$route).then(({visitedViews}) => {})
            }
            next();
          })
          .catch(() => {
            next(false);
          });
    } else {
      next();
    }
  },
}
</script>

<style scoped>
.bg-white {
  overflow-y: auto !important;
}

.minTitle {
  font-weight: bold;
  font-size: 14px;
  margin-top: 20px;
}

.input-new-tag {
  width: 200px;
}

.input-new-tag {
  margin-left: 10px;
}

.button-new-tag {
  margin-left: 10px;
}

.addRepairs {
  .el-col-11 {
    height: 32px !important;
    margin-bottom: 15px;
  }
}

.pageHeight {
  height: calc(100vh - 205px);
  overflow-y: auto;
}
</style>